// forms
form,
fieldset,
.ant-form {
  margin: 0;
  padding: 0;
  border-style: none;
  font-variant-numeric: lining-nums;
}

.ant-input,
.ant-input-number,
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="number"],
textarea,
.ant-picker {
  -webkit-appearance: none;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid var(--gray-lightest);
  padding: 10px 15px;
  border-radius: 2px;
  font-family: var(--base-font);
  font-variant-numeric: lining-nums;
  background-color: var(--gray-lightest) !important;
  max-width: 100%;
  height: 40px;
  font-variant-numeric: lining-nums !important;

  &:hover {
    border-color: var(--blue);
  }

  &:focus {
    border-color: var(--blue);
    box-shadow: none;
  }

  &::placeholder {
    color: var(--gray);
    font-size: 14px;
  }
}

.ant-input-affix-wrapper {
  padding: 0 15px;

  &:not(&-disabled):hover {
    border-color: var(--blue);
  }

  &:focus,
  &-focused {
    border-color: var(--blue);
    box-shadow: none;
  }
  > .ant-input:not(textarea) {
    padding: 10px 0;
    height: 38px;
  }
}

.ant-input-number {
  width: auto;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number-input,
.ant-input-number-sm input {
  padding: 0;
  height: auto;
  font-variant: lining-nums;
}

.ant-input-sm {
  height: 32px;
}

//textarea

textarea {
  resize: vertical;
  vertical-align: top;
}

textarea + div {
  position: absolute;
  width: 30px !important;
  top: 6px !important;
  right: 5px !important;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

//radio-button
.ant-radio-group {
  .ant-radio-button-wrapper {
    letter-spacing: 1.1px;
    color: var(--black);
    padding: 9px 10px 8px;
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    font-variant-numeric: lining-nums;
    height: auto;
    line-height: 1;

    &:hover {
      border-color: var(--green);

      &:before {
        background-color: var(--green);
      }

      &:after {
        background-color: var(--green);
        z-index: 2;
      }
    }
    &:after {
      position: absolute;
      top: -1px;
      right: -1px;
      display: block;
      box-sizing: content-box;
      width: 1px;
      height: 100%;
      padding: 1px 0;
      background-color: #d9d9d9;
      transition: background-color 0.3s;
      content: " ";
    }

    &.ant-radio-button-wrapper-disabled {
      &:hover {
        border-color: #d9d9d9;

        &:before {
          background-color: #d9d9d9;
        }

        &:after {
          background-color: #d9d9d9;
          z-index: 2;
        }
      }

      &.ant-radio-button-wrapper-checked {
        span {
          color: var(--white);
        }
      }
    }

    &.ant-radio-button-wrapper-checked {
      color: var(--white);
    }
  }
}

// label
.ant-form-item-label > label,
label.field-label {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--gray);
  font-weight: bold;
  letter-spacing: 1.1px;
  font-variant-numeric: lining-nums;
  line-height: 1.5;
}

.ant-form-item-label > label {
  height: 40px;
  position: relative;
  top: 1px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after,
.ant-form-item-label > label > span.ant-form-item-required::after {
  content: "*";
  color: var(--gray);
  font-size: 28px;
  line-height: 1;
  top: 0;
  margin: 0;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: var(--green) !important;
}

//form-box
.form-box {
  background-color: var(--white);
  padding: 20px 40px 0 40px;
  border-radius: 2px;
  margin: 0 0 10px;
}

.ant-form-item {
  margin-bottom: 20px !important;
  &:last-child {
    margin: 0 !important;
  }
}

//form-disabled

.form-disabled {
  opacity: 0.5;
  pointer-events: none;

  :not(&) {
    .ant-input-disabled,
    .ant-picker-disabled {
      opacity: 0.5;
    }
  }
}

.ant-picker.ant-picker-disabled {
  border-color: #f5f9fc;
}

//error
.ant-form-item-explain-error {
  font-size: 12px;
  margin: 5px 0 0 0;
  color: #e64545;
}

.ant-form-item-hidden,
.ant-row.ant-form-item-hidden {
  display: none !important;
}

// ant-select

.ant-select {
  box-shadow: none !important;
  // padding: 3px 10px;

  border-radius: 2px;
  background-color: var(--gray-lightest);

  &.ant-select-single {
    background-color: var(--gray-lightest);
    border: 1px solid transparent;

    .ant-select-selector {
      background-color: var(--gray-lightest) !important;
      box-shadow: none !important;
      height: 38px;
      padding: 5px 15px !important;
      border: 0 !important;
    }

    .ant-select-selection-search .ant-select-selection-search-input {
      height: 38px;
      padding-left: 0;
    }
  }

  &.ant-select-multiple {
    border: 1px solid #f8f9fc;

    .ant-select-selector {
      background-color: var(--white) !important;
      box-shadow: none !important;
      padding: 0 !important;
      border: 0 !important;
    }
  }

  &:focus,
  &:hover {
    border: 1px solid var(--blue) !important;
    border-radius: 2px;
  }

  &.ant-select-status-error {
    border: 1px solid #e64545 !important;
  }

  .ant-select-selector {
    background-color: var(--gray-lightest) !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: 0 !important;
  }

  .ant-select-selection-item {
    background-color: var(--gray-lightest);
    font-variant-numeric: lining-nums !important;
  }

  &.ant-select-open {
    .anticon-down:after {
      transform: rotate(180deg);
    }
  }

  .ant-select-arrow {
    z-index: 200;
  }

  &.select-tags-control {
    border: 1px solid var(--gray-light);
    background-color: var(--white);
    min-height: 100px;

    .ant-select-selection-placeholder {
      top: 15px;
      z-index: 10;
    }

    .ant-select-selection-item {
      background-color: var(--green);
      border: 0;
      padding: 2px 18px 2px 7px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--white);
      height: auto;
      line-height: 16px;
      font-variant-numeric: lining-nums;

      &:hover {
        background-color: var(--green-darker);
        cursor: pointer;
      }
    }

    .ant-select-selection-overflow {
      margin: 5px 10px;
    }
  }

  .ant-select-selection-placeholder {
    background-color: var(--gray-lightest) !important;
    z-index: 10;
  }
}

.ant-select-multiple .ant-select-selector {
  align-items: flex-start;
}

//dropdown
.ant-select-dropdown {
  padding: 0;

  .ant-select-item {
    border: 1px solid transparent;
    font-variant-numeric: lining-nums;

    &:hover {
      background-color: transparent;
      border: 1px solid var(--blue);
    }

    &.ant-select-item-option-selected {
      font-weight: 700;
      background-color: transparent;
    }
  }
}

//ant-picker
.ant-picker {
  width: 100%;

  &.ant-picker-focused {
    box-shadow: none;
    border-color: var(--blue);
  }

  .ant-picker-suffix {
    .anticon-calendar {
      &::after {
        position: absolute;
        content: "\e906";
        font-family: "icomoon";
        font-size: 30px;
        top: 9px;
        right: -10px;
        color: var(--gray);
      }
      svg {
        display: none;
      }
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    background-color: var(--white);
    box-shadow: 0 4px 7px 0 rgba(143, 164, 191, 0.2);
  }

  .ant-picker-super-prev-icon {
    transform: rotate(0) !important;
    width: auto;
    height: auto;

    &:before {
      display: none;
    }

    &:after {
      content: "\e919";
      font-family: "icomoon";
      border-width: 0;
      left: -7px;
      top: -23px;
    }
  }

  .ant-picker-prev-icon {
    transform: rotate(0) !important;

    &:before {
      display: none;
    }

    &:after {
      content: "\e917";
      font-family: "icomoon";
      left: 0;
      top: -15px;
      margin: 0 0 0 10px;
    }
  }

  .ant-picker-header-view {
    display: flex;
    justify-content: center;

    .ant-picker-month-btn,
    .ant-picker-year-btn {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.1px;
      line-height: 15px;
      color: var(--black);
      font-feature-settings: "lnum";
    }
  }

  .ant-picker-next-icon {
    transform: rotate(0) !important;

    &:before {
      display: none;
    }

    &::after {
      content: "\e91e";
      font-family: "icomoon";
      left: 0;
      top: -17px;
      border-width: 0;
      margin: 0 0 0 -24px;
    }
  }

  .ant-picker-super-next-icon {
    transform: rotate(0) !important;

    &:before {
      display: none;
    }

    &::after {
      content: "\e91d";
      font-family: "icomoon";
      left: -1px;
      top: -17px;
      border-width: 0;
    }
  }

  .ant-picker-content {
    th {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.1px;
      color: var(--black);
      text-transform: uppercase;
      font-variant-numeric: lining-nums;
    }

    td {
      font-size: 12px;
      font-variant-numeric: lining-nums;
      padding: 0;

      &.ant-picker-cell-today {
        border-radius: 1px;
        background-color: var(--gray-lightest);
        color: var(--black);
        padding: 0;

        .ant-picker-cell-inner {
          &:before {
            display: none;
          }
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .ant-picker-footer {
    padding: 0 10px 20px;
    border: 0;

    .ant-picker-today-btn {
      height: 40px;
      width: 232px;
      border: 1px solid var(--gray-light);
      border-radius: 2px;
      background-color: var(--white);
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      color: var(--black);
      margin: 0 auto;
    }
  }
}

.ant-picker-header > button {
  font-size: 10px;
}

.ant-picker-header {
  border-bottom: 0;
}

.textarea-overlay {
  .ant-upload {
    &-select,
    &-select-text {
      height: 100%;
      width: 100%;
    }
  }
}

.assigned-to-input {
  line-height: 30px;
  font-size: 14px;

  .ant-col-offset-1 {
    margin-left: 2%;
  }
}

.form-box-storage-service {
  .ant-alert-success {
    border: 1px solid var(--green);
    border-radius: 2px;
    background-color: rgba(47, 191, 0, 0.1);
  }

  .ant-alert {
    height: 40px;
  }
}

//ant-form-item-extra
.ant-form-item-extra {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  color: var(--gray);
  word-break: break-all;
  padding-top: 5px;
}

.ant-form-item-label {
  white-space: normal;
}

.ant-select-show-arrow .ant-select-clear {
  right: 40px;
}

.ant-picker-clear {
  right: 25px;
}

.ant-form-item-control-input {
  min-height: 40px;
}

.ant-col-24 > .ant-form-item-control-input {
  min-height: 0;
}

.form-item--sm .ant-form-item-control-input {
  min-height: 32px;
}

.ant-col-24.ant-form-item-label > label {
  height: auto;
}

.ant-input-group-addon {
  border: 0;
  background: var(--gray-lightest);
  color: var(--gray);
}

.ant-cascader-menu {
  height: auto;
  max-height: 300px;
}

.ant-form-item-label > label .ant-form-item-optional {
  color: var(--gray-light);
}
