.ant-table {
  border: 1px solid var(--gray-light);
  border-radius: 2px;

  th.ant-table-cell {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: transparent;
    border-bottom: none;
  }

  .ant-table-thead > tr > th {
    padding: 18px 15px 16px;
    width: auto;

    &:first-child {
      padding: 18px 16px 16px 30px;
    }

    &:last-child {
      padding: 18px 30px 16px 16px;
      align-content: end;
    }

    .ant-table-column-sorters {
      white-space: nowrap;
    }
    &.release-date-column {
      width: 130px;
    }

    &.upc-column,
    &.artist-column,
    &.title-column {
      width: 150px;
    }

    &.label-column,
    &.version-column {
      width: 95px;
    }

    &.status-column {
      width: 170px;
    }

    &.configuration-column {
      width: 115px;
    }

    &.name-of-partner-column {
      width: 160px;
    }

    &.ddex-party-id-column,
    &.primary-contact-name-column {
      width: 190px;
    }

    &.assigned-user-cell,
    &.contacts-cell {
      min-width: 145px;
    }

    &.status-partner-column {
      width: 120px;
      text-overflow: unset;
    }

    &.template-name-column,
    &.type-column,
    &.description,
    &.updatedBy,
    &.updatedAt {
      width: 170px;
    }
  }

  .ant-table-tbody > tr.ant-table-row > td {
    border-bottom: none;
    font-weight: 400;
    padding: 10px 15px;
    font-variant-numeric: lining-nums;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      padding: 10px 16px 10px 30px;
      border-left: 1px solid transparent;
    }

    &:last-child:not(:only-child) {
      padding: 10px 30px 10px 16px;
      border-right: 1px solid transparent;
    }

    &.status-partner-column,
    &.contacts-cell,
    &.assigned-partners,
    &.status-user-column,
    &.assigned-user-cell {
      text-overflow: unset;
    }

    .ant-btn-icon-only {
      margin: 0;
    }
  }

  .ant-table-tbody > tr.odd {
    background-color: rgba(207, 216, 230, 0.3);
  }

  .ant-table-column-sorters {
    padding: 0;
  }

  .anticon-caret-down,
  .anticon-caret-up {
    font-size: 9px;
  }

  .ant-table-column-sorter-full {
    margin-top: 0 !important;
  }

  .ant-table-column-sorter {
    margin-left: 12px !important;
  }

  th.contacts-cell {
    padding: 20px 15px 16px !important;
    margin: auto;
  }

  .ant-empty-image {
    .ant-empty-img-simple {
      display: none;
    }
  }
}

.ant-radio-group {
  display: flex;
  border-radius: 2px 0 0 2px;

  label {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f2fcff;
  border-bottom: 1px solid var(--blue);
  border-top: 1px solid var(--blue);
  cursor: pointer;

  &:first-child {
    border-left: 1px solid var(--blue);
  }

  &:last-child {
    border-right: 1px solid var(--blue);
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #dee2e7;
  cursor: pointer;
}

.ant-table-thead th:hover {
  background-color: #dee2e7;
  cursor: pointer;
}

.contacts-cell {
  min-height: 57px;
  svg {
    width: 30px;
    height: 30px;

    &:hover {
      cursor: pointer;

      path {
        fill: var(--blue);
      }
    }
  }
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none !important;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none !important;
}

.upc-column {
  width: 175px !important;

  .upc-wrapper {
    position: relative;
    padding: 0 8px 0 0;

    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 6px 6px;
      border-color: transparent transparent #007bff transparent;
      right: 0;
      bottom: 0;
    }
  }
}

.sidebar-collapsed {
  .ant-table-thead > tr > th {
    &.name-of-partner-column {
      width: 210px;
    }
    &.sidebar-collapsed {
      width: 170px;
    }
  }
}

.truncated {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
