.login-form {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - var(--main-content-padding-bottom) - var(--main-content-padding-top));

  &__box {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    border: var(--gray-lightest) 1px solid;
    border-radius: 8px;
    padding: 20px 40px 28px;
  }

  &__error {
    color: var(--red);
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;